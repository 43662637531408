import { Controller } from "@hotwired/stimulus"
// https://scottw.com/articles/stimulus_toggler_for_tailwindui/
// TODO: 1) check how targets work exactly. Maybe nodeName check isn't needed that way. (https://stimulus.hotwired.dev/reference/targets)
//       2) aria-checked="<%= template.active %>" on the button should be taken in account in here too
export default class extends Controller {
  static targets = ["element"];

  toggle(event) {
    event.preventDefault();
    this.elementTargets.forEach((element) => {
      if (element.classList.contains("hidden")) {
        element.classList.remove("hidden");
        element.classList.add("block");
      } else {
        element.classList.add("hidden");
        element.classList.remove("block");
      }
    });
  }
}